import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.stock?.[10]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.stock?.[11]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.stock?.[8]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.stock?.[9]}")` }}
        ></figure>
        <div className="z-10 relative w-4/5 mx-auto pt-[350px] pb-[100px] md:pb-[150px] md:pt-[380px] flex flex-col md:flex-row justify-center items-center">
          <div className="w-full md:w-1/2 flex flex-col justify-center items-start">
            <h1 className="text-white text-center md:text-start text-2xl md:text-6xl">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <p className="text-white text-center md:text-start">{rpdata?.dbValues?.[1].description}</p>
            <div className="flex justify-center md:block">
              <ButtonContent />
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/banderas%2Froofing-process-compressor.gif?alt=media&token=19905382-2a68-4c54-a574-baa21f96a2e4" alt="img-home" className="w-full h-auto object-contain" />
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
